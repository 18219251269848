<template>
    <dialogBox 
        @close="this.$emit('close')"
    >
      <template v-slot:title>{{ $t('Add') }}</template>
      <template v-slot:body>
            <div class="mb-3">
                <b-row>
                        <b-col lg="4">
                            <label class="fs-14 fw-semibold">{{ $t('selectType') }}</label>
                            <select
                                class="form-select mb-3"
                                v-model="selected.selectedType"
                            >
                            <option v-for="typ in listType" :key="typ" :value="typ" >{{typ.title}}</option>
                          </select>
                        </b-col>
                        <b-col lg="4" v-if="selected.selectedType != ''">
                            <label class="fs-14 fw-semibold">{{ $t('selectValue') }}</label>
                            <textarea v-if="selected.selectedType.value == 'search'" class="form-control" @input="inputSearch( $event )"></textarea>
                            <select
                                v-if="selected.selectedType.value == 'types'"
                                class="form-select mb-3"
                                v-model="selected.selectedValue"
                            >
                                <option v-for="val in listTypes" :key="val" :value="val" >{{val.title}}</option>
                            </select>
                            <select
                                v-if="selected.selectedType.value == 'pr1'"
                                class="form-select mb-3"
                                v-model="selected.selectedValue"
                            >
                                <option v-for="val in listDate" :key="val" :value="val" >{{val.title}}</option>
                            </select>
                            <select
                                v-if="selected.selectedType.value == 'statuses'"
                                class="form-select mb-3"
                                v-model="selected.selectedValue"
                            >
                                <option v-for="val in listValue" :key="val" :value="val" >{{val.title}}</option>
                            </select>
                            <select
                                v-if="selected.selectedType.value == 'decidedStatuses'"
                                class="form-select mb-3"
                                v-model="selected.selectedValue"
                            >
                                <option v-for="val in decidedStatusList" :key="val" :value="val" >{{val.title}}</option>
                            </select>
                            <select
                                v-if="selected.selectedType.value == 'priorities'"
                                class="form-select mb-3"
                                v-model="selected.selectedValue"
                            >
                                <option v-for="val in listPriority" :key="val" :value="val" >{{val.title}}</option>
                            </select>
                            <select
                                v-if="selected.selectedType.value == 'problemEnvironments'"
                                class="form-select mb-3"
                                v-model="selected.selectedValue"
                            >
                                <option v-for="val in listProblem" :key="val" :value="val" >{{val.title}}</option>
                            </select>
                            <select
                                v-if="selected.selectedType.value == 'workerIds'"
                                class="form-select mb-3"
                                v-model="selected.selectedValue"
                            >
                                <option v-for="val in listWorkers" :key="val" :value="val" >{{val.title}}</option>
                            </select>
                            <select
                                v-if="selected.selectedType.value == 'inJobWorkerIds'"
                                class="form-select mb-3"
                                v-model="selected.selectedValue"
                            >
                                <option v-for="val in listWorkers" :key="val" :value="val" >{{val.title}}</option>
                            </select>
                        </b-col>
                        <b-col lg="2" v-if="selected.selectedType != '' && selected.selectedValue != ''">
                            <label class="fs-14 fw-semibold">{{ $t('selectColor') }}</label>
                            <span class="copy" v-if="selected.selectedType != '' && selected.selectedValue != ''" @click="openColorBox = true">
                                <div class="rounded text-white text-center p-2 fw-semibold" :style="`word-break: break-all;background-color: ${selected.selectedColor};`">
                                    {{ selected.selectedValue.title }}
                                </div>
                            </span>
                        </b-col>
                        <b-col lg="2" v-if="selected.selectedType != '' && selected.selectedValue != ''">
                            <button type="button" class="btn btn-soft-info btn-icon btn-sm fs-16" style="margin-right: 5px;margin-top: 30px;" @click="multiAdd(selected)"><i class="bx bx-add-to-queue align-bottom"></i></button>
                            <button v-if="mutliFilter.value.length == 0" type="button" class="btn btn-soft-success btn-icon btn-sm fs-16" style="margin-right: 5px;margin-top: 30px;" @click="changeTabs(selected)"><i class="ri-add-fill align-bottom"></i></button>
                        </b-col>
                </b-row>
            </div>
            <div v-if="mutliFilter.value.length > 0">
                <div class="mb-2 d-flex">
                    <div class="w-25" style="margin-right: 5px;">
                        <label class="fs-14 fw-semibold">Таб</label>
                        <span class="copy" v-if="selected.selectedType != '' && selected.selectedValue != ''" @click="openColorBox = true">
                            <div class="rounded text-white text-center p-2 fw-semibold" :style="`word-break: break-all;background-color: ${mutliFilter.color};`">
                                {{ mutliFilter.title }}
                            </div>
                        </span>
                    </div>
                    <button type="button" @click="saveMulti(mutliFilter)" class="btn btn-success btn-icon btn-sm fs-16" style="margin-right: 5px;margin-top: 30px;"><i class="ri-save-3-fill align-bottom"></i></button>
                </div>
                <div class="align-items-center border border-dashed p-2 rounded mb-2">
                    <template v-for="(item, index) in mutliFilter.value" :key="index">
                    <div class="d-flex">
                        <!-- <div class="copy ">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                :id="item.tab + index"
                                v-model="item.checked"
                            />
                        </div> -->
                        <div class="flex-grow-1 ms-3 copy">
                            <label class="fs-14 fw-semibold text-white copy bg-dark" :for="item.tab+index"  :style="`padding: 8px;border-radius:6px;display:block;width:90%;`">
                                {{ item.title }}
                            </label>
                        </div>
                        <div class="hstack gap-3 fs-16">
                            <a class="text-muted copy" @click="removeChild(index)">
                                <i class="ri-delete-bin-line"></i>
                            </a>
                        </div>
                    </div>
                </template>
                </div>
                <hr />
            </div>
            <div>
                <template v-for="(item, index) in tabs" :key="item">
                    <div class="align-items-center border border-dashed p-2 rounded mb-2">
                        <div class="d-flex ">
                            <div class="copy ">
                                <!-- <div class="avatar-title bg-light text-secondary rounded fs-24"><i class="ri-video-line"></i></div> -->
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :id="item.tab + index"
                                    v-model="item.checked"
                                />
                            </div>
                            <div class="flex-grow-1 ms-3 copy">
                                <label class="fs-14 fw-semibold text-white copy" :for="item.tab+index" :style="`background: ${item.color};padding: 8px;border-radius:6px;display:block;width:90%;`">
                                    {{ item.title }}
                                </label>
                            </div>
                            <div class="hstack gap-3 fs-16">
                                <a class="text-muted copy" v-if="Array.isArray( item.value )" @click="item.hide = !item.hide">
                                    <i :class="`${item.hide == false ? 'bx bx-hide' : 'bx bx-info-circle'}`"></i>
                                </a>
                                <a class="text-muted copy" @click="removeTab(index)">
                                    <i class="ri-delete-bin-line"></i>
                                </a>
                            </div>
                        </div>
                        <template v-if="Array.isArray( item.value ) && item.hide == false" v-for="op in item.value" :key="op">
                            <div class="d-flex w-75" style="margin-left: 30px;">
                                <div class="flex-grow-1 ms-3 copy">
                                    <label class="fs-14 fw-semibold text-black badge badge-soft-info" :for="item.tab+index" :style="`padding: 8px;border-radius:6px;display:block;width:90%;text-align:left`">
                                        {{ op.title }} <span class="text-muted fs-10"> | {{ $t('type') }} - {{ getTypeTitle(op.obj) }}</span>
                                    </label>
                                </div>
                            </div>
                        </template>
                    </div>
                    
                </template>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button type="button" class="btn btn-success mt-3" @click="save">{{ $t('save') }}</button>
        </template>
    </dialogBox>

    <dialogBox v-if="openColorBox" @close="openColorBox = false; editTitle = false">
        <template v-slot:title>{{ $t('change color') }}</template>
        <template v-slot:body>
            <div class="border-bottom mb-3 pb-3">
                <b-row>
                    <b-col lg="12">
                        <div class="rounded text-white text-center p-3" :style="`background-color: ${ mutliFilter.value == 0 ? selected.selectedColor : mutliFilter.color};`">
                            <span @dblclick="editTitle = true" v-if="editTitle == false" class="fs-16 fw-semibold">{{ mutliFilter.value == 0 ? selected.selectedValue.title : mutliFilter.title }}</span>
                            <input v-if="mutliFilter.value == 0 && editTitle == true" class="form-control" v-model="selected.selectedValue.title" :style="`border: none;border-bottom: 1px solid white;background: ${selected.selectedColor};color:white`" />
                            <input v-if="mutliFilter.value != 0 && editTitle == true" class="form-control" v-model="mutliFilter.title" :style="`border: none;border-bottom: 1px solid white;background: ${mutliFilter.color};color:white`"/>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <b-card>
                <b-row>
                    <template v-for="item in colorsNew" :key="item">
                        <b-col lg="2" class="mb-2 copy" @click="mutliFilter.value == 0 ? selected.selectedColor = item.color : mutliFilter.color = item.color ">
                            <div class="avatar-sm" >
                                <div class="avatar-title rounded text-white" :style="`background-color: ${item.color};`"></div>
                            </div>
                        </b-col>
                    </template>
                </b-row>
            </b-card>
        </template>
        <template v-slot:footer-bottom>
            <button type="button" class="btn btn-success mt-3" @click="openColorBox = false; editTitle = false">{{ $t('save') }}</button>
        </template>
    </dialogBox>

</template>

<script>
// import dialogBox from '@/components/modal/dialog'
import dialogBox from '@/components/modal/modal-lg'
import { storeS } from '@/store'

export default{
    props: ['othertabs'],
    components: { dialogBox },
    data(){
        return{
            form: '',
            openColorBox: false,
            editTitle: false,
            selected: {
                selectedType: '',
                selectedValue: '',
                selectedColor: '#1ABC9C',
            },
            mutliFilter: {
                title: '#TabName',
                tab: '',
                color: '#1ABC9C',
                obj: '',
                value: [],
                hide: true,
                checked:  true
            },
            listType: [
                { title: this.$t('searc_inp'), value: 'search' },
                { title: this.$t('date'), value: 'pr1' },
                { title: this.$t('ticket_type'), value: 'types' },
                { title: this.$t('problem_area'), value: 'problemEnvironments' },
                { title: this.$t('Status'), value: 'statuses' },
                { title: this.$t('decidedStatus'), value: 'decidedStatuses' },
                { title: this.$t('priority'), value: 'priorities' },
                { title: this.$t('added'), value: 'workerIds' },
                { title: this.$t('inJobWorker'), value: 'inJobWorkerIds' },
            ],
            listTypes: [
                { title: this.$t('problem_to_develop'), value: 'problem' },
                { title: this.$t('wish_to_develop'), value: 'wishes' },
            ],
            listValue: [
                { title: this.$t('new'), value: 'new' },
                { title: this.$t('statusInWork'), value: 'jobs' },
                { title: this.$t('statusCompleted'), value: 'decided' },
            ],
            decidedStatusList: [
                { title: this.$t('waitTesting'), value: 'waittesting' },
                { title: this.$t('waitRelease'), value: 'waitrelease' },
                { title: this.$t('inRelease'), value: 'in_release' },
                { title: this.$t('noProblem'), value: 'noProblem' },
            ],
            listPriority: [
                { title: this.$t('Critical'), value: '3' },
                { title: this.$t('priorityHigh'), value: '2' },
                { title: this.$t('priorityMedium'), value: '1' },
                { title: this.$t('priorityLow'), value: '0' },
            ],
            colorsNew: [
                { color: '#1ABC9C', title: '1ABC9C' },
                { color: '#2ECC71', title: '2ECC71' },
                { color: '#3498DB', title: '3498DB' },
                { color: '#9B59B6', title: '9B59B6' },
                { color: '#34495E', title: '34495E' },
                { color: '#F1C40F', title: 'F1C40F' },
                { color: '#F39C12', title: 'F39C12' },
                { color: '#E74C3C', title: 'E74C3C' },
                { color: '#C2185B', title: 'C2185B' },
                { color: '#BDC3C7', title: 'BDC3C7' },
                { color: '#95A5A6', title: '95A5A6' },
                { color: '#607D8B', title: '607D8B' },
            ],
            listDate: [
                {title: this.$t('Today'), value: 'today'},
                {title: this.$t('Yesterday'), value: 'yesterday'}
            ],
            listProblem: [],
            listWorkers: [],
            tabs: [],
            colors:[
                {
                    title: this.$t('color_green'),
                    value: "success"
                },
                {
                    title: this.$t('color_orange'),
                    value: "warning"
                },
                {
                    title: this.$t('color_blue'),
                    value: "info"
                },
                {
                    title: this.$t('black'),
                    value: "primary"
                },
                {
                    title: this.$t('color_red'),
                    value: "danger"
                }
            ],
        }
    },
    created(){
        this.changeSelectDevProjects()

        for(var item in this.workers){
            this.listWorkers.push({
                title: this.workers[item].workerName,
                value: this.workers[item].workerId
            })
        }
        this.tabs = this.othertabs;

        this.randomColor();
    },
    methods: {
        inputSearch(e){
            var obj = {
                title: e.target.value,
                value: e.target.value
            }
            this.selected.selectedValue = obj
        },
        multiAdd(selected){
            console.log('selected',selected.selectedValue);
            var filter = {
                title: selected.selectedValue.title,
                tab: selected.selectedType.value+selected.selectedValue.value,
                color: selected.selectedColor,
                obj: selected.selectedType.value,
                value: selected.selectedValue.value,
                checked: true
            }

            this.mutliFilter.value.push(filter)
        },
        randomColor(){
            let colors = this.othertabs.map(elem => elem.color);
            let colorsSet = new Set(colors);
            let notColors = this.colorsNew.filter(elem => !colorsSet.has(elem.color));
            var ind = Math.floor(Math.random() * (notColors.length )) + 0
            if(notColors.length != 0){
                this.selected.selectedColor = notColors[ind].color
                this.mutliFilter.color = notColors[ind].color
            } else {
                this.selected.selectedColor = '#1ABC9C'
                this.mutliFilter.color = '#1ABC9C'
            }
            
        },
        saveMulti(f){
            var tab = {
                title: f.title,
                tab: 'tab1',
                color: f.color,
                obj: 'tab1',
                value: Array.from(f.value),
                hide: true,
                checked: true
            }	
            console.log('f', f);
            console.log('tab', tab);
            this.tabs.push(tab);
            this.mutliFilter.value = []
        },
        changeTabs(selected){
            console.log(selected);
            var tab = {
                title: selected.selectedValue.title,
                // tab: "priorityMedium",
                tab: selected.selectedType.value+selected.selectedValue.value,
                color: selected.selectedColor,
                obj: selected.selectedType.value,
                value: selected.selectedValue.value,
                hide: true,
                checked: true
            }	
            console.log('tab', tab);
            this.tabs.push(tab)
            this.randomColor();
            // this.$emit('changeTabs', this.tabs)
            // this.$emit('close');
        },
        removeChild(i){
            console.log('111', this);
            this.mutliFilter.value.splice(i, 1);
            console.log('other', this);
        },
        removeTab(i){
            this.tabs.splice(i, 1)
        },
        save(){
            this.$emit('changeTabs', this.tabs)
            this.$emit('close');
        },
        getTypeTitle(item){
            var title = '';
            this.listType.forEach(element => {
                if(element.value == item){
                    title = element.title
                }
            });
            return title;
        },
        changeSelectDevProjects(){
            // Робимо вигляд проєктів під мультіселект

            this.devProjects = [];
            for(var item in this.developmentProjects){
                var project = this.developmentProjects[item]
                this.listProblem.push({
                    "title": project.projectName,
                    "value": project.projectKey
                })
            }
            
        },
    },
    computed: {
        workers(){
            return storeS.workers
        },
        developmentProjects() {
            // Отримання проектів
            return storeS.developmentProjects
        }
    }
}
</script>