<template>

  <!-- шапка сторінки -->
    <PageHeader :title="title" />

    <!-- 
      Блок з кнопкою для включення інфо-блоків. 
      Є перевірка:
        - Налаштування користувача 001 є true
    -->
    <div class="col-sm-auto" style="margin-bottom:15px;" >
      <button 
        :title="this.$t('onInfoBLocks')"
        id="info" 
        v-if="this.checks['001'] == true"
        @click="infoblockShow = !infoblockShow" 
        type="button" 
        :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"
        ><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i>
      </button>

      <button 
        :title="this.$t('unclosedTicketsShow')"
        id="info" 
        @click="toggleButton('unclosedTicketsShow')" 
        type="button" 
        style="margin-left: 10px;"
        :class="`btn btn-${unclosedTicketsShow == true ? 'primary' : 'success'} waves-effect waves-light`"
        ><i :class="`${unclosedTicketsShow == true ? 'ri-ticket-2-line' : 'ri-team-line'}`"></i>
      </button>

      <button 
        :title="this.$t('unclosedTicketsByWorkersShow')"
        id="info" 
        @click="toggleButton('unclosedTicketsByWorkersShow')" 
        type="button" 
        style="margin-left: 10px;"
        :class="`btn btn-${unclosedTicketsByWorkersShow == true ? 'primary' : 'secondary'} waves-effect waves-light`"
        ><i :class="`${unclosedTicketsByWorkersShow == true ? 'ri-ticket-2-line' : 'bx bxl-dev-to'}`"></i>
      </button>

      <button 
        :title="this.$t('unclosedTicketsByEnvironmentsShow')"
        id="info" 
        @click="toggleButton('unclosedTicketsByEnvironmentsShow')" 
        type="button" 
        style="margin-left: 10px;"
        :class="`btn btn-${unclosedTicketsByEnvironmentsShow == true ? 'primary' : 'warning'} waves-effect waves-light`"
        ><i :class="`${unclosedTicketsByEnvironmentsShow == true ? 'ri-ticket-2-line' : 'ri-device-line'}`"></i>
      </button>

    </div>

    <b-row>
      <!-- Зведення по статусам -->
      <b-col lg="6" v-if="infoblockShow">
          <b-card>
              <div class="card-body" >
                  <h5 class="card-title mb-1 fw-semibold">{{ $t('StatusSummary') }}</h5>
                  <p class="card-text">{{ $t('StatusSummaryTickets') }}</p>
                  <div class="cdc-container_flex mt-4">
                      <vc-donut
                          :size="200"
                          unit="px"
                          :thickness="35"
                          :background="`${statusTheme == 'dark' ? '#212529' : 'white'}`"
                          foreground="#eeeeee"
                          :sections="highlightedSections"
                          @section-mouseenter="setHovered"
                          @section-mouseleave="resetHovered"
                      >
                          <template v-if="hoveredValue != null">
                              <h2>{{ hoveredValue }}%</h2>
                              {{ hoveredLabel }}
                          </template>
                          <template v-else>
                              <h2>{{ sections[sections.length-1].value }}%</h2>
                              {{ sections[sections.length-1].label }}
                          </template>
                      </vc-donut>
                      <div class="cdc-legend w-100" style="flex-direction: column; margin: 0px 0px 0px 1em;">
                          <span 
                              v-for="section in sections" 
                              :key="section.label" 
                              class="cdc-legend-item-section pe-2" 
                              @mouseover="setHovered(section)"
                              @mouseleave="resetHovered"
                          >
                              <div class="d-flex w-100" style="align-items: center;">
                                  <div class="flex-grow-1 ms-2">
                                      <span class="cdc-legend-item">
                                          <span class="cdc-legend-item-color" :style="`background-color: ${section.color};`"></span>
                                          <span class="fw-semibold fs-13">{{ section.label }}</span>
                                      </span>
                                  </div>
                                  <div class="flex-shrink-0 text-end">
                                      <h6 style="margin: 0;">{{ section.count }} <span class="text-muted fs-11">{{ section.value }}%</span></h6> 
                                  </div>
                              </div>
                          </span>
                          <span class="cdc-legend-item-section pe-2" >
                              <div class="d-flex w-100"  style="align-items: center;">
                                  <div class="flex-grow-1 ms-2" >
                                      <span class="cdc-legend-item" ><span class="fw-semibold fs-13" >{{ $t('totalTickets') }}</span></span>
                                  </div>
                                  <div class="flex-shrink-0 text-end" ><h6  style="margin: 0px;">{{ this.information[0].value }}</h6></div>
                              </div>
                          </span>

                      </div>
                  </div>
              </div>
          </b-card>
      </b-col>
    </b-row>
    
    <!-- інфо-блоки -->
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

    <!-- <input type="checkbox" :value="true" @click="hideDecided($event)"/> -->

    <!-- 
      Компонент таблиці 
      @search - дія пошуку
      @addCategory - дія додавання табу
      @removeCategory - дія видалення табу
      @create - дія створення тікету
      @open - дія відкриття тікету
      @changeFilter - дія зміни фільтру (табу)
      @clearFilter - дія очистки фільтру
      @remove - дія видалення тікету
      @getdata - дія отримання даних по тікетам
      @changelimit - зміна кількості відображення тікетів
      @changeTab - зміна табу
      :dataParams - пропс базових параметрів
      :objParams - пропс передачі парметрів (фільтрів, що будуть доступні)
      :columns - прпос передачі колонок
      :rows - пропс передачі самих тікетів
      :pages - пропс передачі номеру сторінки
      :showpag - пропс показування пагінації
      :tabs - пропс, що передає інформацію про таби
      :addtabs - дозвіл на додаваннrsя своїх табів
      :createdform - пропс на додавання тікетів
    -->
    <tablecustom 
      v-if="!unclosedTicketsShow && !unclosedTicketsByWorkersShow && !unclosedTicketsByEnvironmentsShow"
      @search="searchB"
      @addCategory="showFilterBox = true"
      @removeCategory="removeCategory" 
      @create="perms[666] ? createmodalNewForm = true : createmodal = true"
      @open="open" 
      @changeFilter="changeFilter" 
      @clearFilter="clearFilter" 
      @remove="remove" 
      @getdata="getdata"
      @changelimit="changelimit"
      @changeTab="changeTab"
      @changeWithClearFilter="changeWithClearFilter"
      :dataParams="dataParams"
      :objParams="objParams"
      :columns="columns" 
      :rows="rows" 
      :pages="objPages"
      :showpag="showpag"
      :tabs="othertabs"
      :addtabs="true"
      :createdform="this.perms[301] || this.perms[2000]"
      @sortColumn="sortColumn" 
    />

    <unclodesTickets 
      v-if="unclosedTicketsShow"
      :title="this.$t('unclosedTicketsShow')"
      :objData="unclodesTickets"
    />

    <unclodesTicketsByWorkers 
      v-if="unclosedTicketsByWorkersShow"
      :title="this.$t('unclosedTicketsByWorkersShow')"
      :objData="unclodesTickets"
    />

    <unclodesTicketsByEnvironments 
      v-if="unclosedTicketsByEnvironmentsShow"
      :title="this.$t('unclosedTicketsByEnvironmentsShow')"
      :objData="unclodesTickets"
    />


    <!-- 
      Модалка перегляду тікету
        @close - подія закриття перегляду тікету
        @prevOpen - дія на відкриття минулого тікету
        @nextOpen - дія на відкриття наступного тікету
        @getdata - дія отримання даних по тікетам
        @edit - дія на редагування тікету
        :obj - пропс передачі даних по тікету 
        :ifPrevopen - пропс на стрілочку вліво
        :ifNextopen - пропс на стрілочку вправо
    -->
    <viewbox 
      v-if="showModal" 
      @close="closeModal" 
      @prevOpen="prevOpen" 
      @nextOpen="nextOpen"
      @getdata="getdata"
      @edit="editTicket"
      :obj="obj" 
      :ifPrevopen="ifPrevopen" 
      :ifNextopen="ifNextopen" 
    ></viewbox>

    <!--
      Модалка створення тікету
        @close - закрити створення тікету
        :perm - пропс перму на редагування
        :obj - пропс для зміни тікету, що передає інфу по ньому
    -->
    <createBox 
      v-if="createmodal == true" 
      @close="closeCreate"
      :perm="this.permToEdit" 
      :obj="this.form"
    ></createBox>

    <!-- Тестове нове вікно створення тікету -->
    <createTicketNewForm 
      v-if="createmodalNewForm == true" 
      @close="closeCreate"
      :perm="this.permToEdit" 
      :obj="this.form"
    ></createTicketNewForm>

    <!--
      Модалка створення табів
        @close - дія закриття табів
        @changeTabs - дія зміни табу
        :othertabs - пропс табів
    -->
    <filterTabs 
      v-if="showFilterBox" 
      @close="showFilterBox = false"
      @changeTabs="changeTabs"
      :othertabs="othertabs"
    >
    </filterTabs>


</template>

<script>
import PageHeader from "@/components/page-header";
import filterTabs from './filterTabs/index.vue'
import tablecustom from '@/components/globaltable/index';
import infoblocks from '@/components/info/blocks'
import { Tickets } from '@/API.js';
import { storeS } from '@/store'
import createBox from '../newtickets/createticket'
import createTicketNewForm from '../newtickets/createTicketNewForm'
import viewbox from '../newtickets/view/index.vue'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { mutateTypeTicket, 
        mutateStatusTicket,
        mutateDecidedStatusTicket,
        mutatePriorityTicket,
        mutateProblemEnv,
        nameTag
      } from '@/usabilityScripts/globalMutate.js'
import unclodesTickets from './unclodesTickets';
import unclodesTicketsByWorkers from './unclodesTicketsByWorkers.vue';
import unclodesTicketsByEnvironments from './unclodesTicketsByEnvironments.vue';
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
    
let apiServe = new Tickets();

export default {
  components: {
    PageHeader,
    tablecustom,
    viewbox,
    createBox,
    Multiselect,
    infoblocks,
    filterTabs,
    unclodesTickets,
    unclodesTicketsByWorkers,
    unclodesTicketsByEnvironments,
    createTicketNewForm
  },
  data() {
    return {
      showpag: true,
      tour: null,
      checkDec: false,
      title: this.$t('tickets'),
      show_in_preloader: false,
      permToEdit: false,
      createmodal: false,
      createmodalNewForm: false, // для тестової модалки створення тікету
      infoblockShow: false,
      unclosedTicketsShow: false, // відображення тікетів по клієнтам групами
      unclosedTicketsByWorkersShow: false, // відображення тікетів по розробникам групами
      unclosedTicketsByEnvironmentsShow: false, // відображення тікетів по проблемам групами
      unclodesTickets: {
        items: {}
      },
      showFilterBox: false,
      info: null,
      showModal: false,
      obj: {},
      objPages: {},
      ifNextopen: "",
      ifPrevopen: "",
      dataParams: {
          status: true,
          page: "tickets"
      },
      objParams:{
        page: '1',
        pagelimit: '25',
        search: '',
        priorities: JSON.stringify([]),
        statuses: JSON.stringify([]),
        decidedStatuses: JSON.stringify([]),
        assignedWorkerIds: JSON.stringify([]),
        types: JSON.stringify([]),
        workerIds: JSON.stringify([]),
        inJobWorkerIds: JSON.stringify([]),
        problemEnvironment: '',
        pr1: '',
        pr2: '',
        tags: '',
        of: 'create_datetime',
        ot: 'desc'
      },
      columns: [
        {
          name: "ID",
          text: "ticketId",
          align: "left",
          status: true,
          width: '10'
        },
        {
          name: this.$t("ticket"),
          text: "type",
          align: "left",
          mutate: (item) => this.mutateticket(item),
          status: true,
          width: '10'
        },
        {
          name: this.$t("problem_area"),
          text: "problemEnvironment",
          align: "left",
          mutate: (item) => item != null ? this.mutateProblemEnv(item) : '-',
          status: true,
          width: '10'
        },
        {
          name: this.$t("ticket_name"),
          text: "ticketName",
          align: "left",
          status: true,
          mutate: (item) => this.mutateTicketName(item),
          width: '10'
        },
        // {
        //   name: this.$t("description"),
        //   text: "createComment",
        //   align: "left",
        //   status: false
        // },
        {
          name: this.$t("priority"),
          text: "priority",
          align: "right",
          mutate: (item) => this.mutatepriority(item).name,
          mutateClass: (item) => this.mutatepriority(item).color,
          status: true,
          width: '10'
        },
        {
          name: this.$t("added"),
          text: "createWorkerId",
          align: "right",
          mutate: (item) => this.nameWorker(item),
          status: true,
          width: '10'
        },
        {
          name: this.$t("date"),
          text: "createDatetime",
          align: "right",
          status: true,
          sort: 'create_datetime',
          sortType: 'asc',
          width: '10'
        },
        {
          name: this.$t("Status"),
          text: "status",
          align: "right",
          mutate: (item) => this.mutatestatus(item).name,
          mutateClass: (item) => this.mutatestatus(item).color,
          status: true,
          width: '10'
        },
        {
          name: this.$t("decidedStatus"),
          text: "decidedStatus",
          align: "right",
          mutate: (item) => this.mutateDecidedStatus(item).name,
          mutateClass: (item) => this.mutateDecidedStatus(item).color,
          status: true,
          width: '10'
        },
        {
          name: this.$t("clients"),
          text: "userPhones",
          align: "right",
          mutate: (item) => item ? this.mutateArray(item, 'userPhones') : '-',
          status: false,
          width: '10'
        },
        {
          name: this.$t("statusInWork"),
          text: "inJobWorkers",
          align: "right",
          mutate: (item) => item ? this.mutateArray(item, 'inJobWorkers') : '-',
          status: true,
          width: '10'
        },
        {
          name: this.$t("appointed"),
          text: "assignedWorkers",
          align: "right",
          mutate: (item) => item ? this.mutateArray(item, 'assignedWorkers') : '-',
          status: true,
          width: '10'
        },
        {
          name: this.$t("labels"),
          text: "tags",
          align: "right",
          status: false,
          mutate: (item) => item ? this.nameTag(item) : '-',
          width: '10'
        },
      ],
      othertabs: [],
      colors: [],
      checked: false,
      rows: [],
      information: [
        {
            title: this.$t('totalTickets'),
            value: "0",
            icon: "ri-ticket-2-line",
            color: "info"
        },
        {
            title: this.$t('new'),
            value: "0",
            icon: "ri-ticket-2-line",
            color: "info"
        },
        {
            title: this.$t('statusInWork'),
            value: "0",
            icon: "mdi mdi-timer-sand",
            color: "warning"
        },
        {
            title: this.$t('completedTickets'),
            value: "0",
            icon: "ri-checkbox-circle-line",
            color: "success"
        }
      ],
      hoveredLabel: 'Hover over a section',
      hoveredValue: null,
      activeSection: null,
      sections: [],
    };
  },
  created() {
    this.objParams.pagelimit = storeS.pagelimit ? storeS.pagelimit : this.objParams.pagelimit
    this.getdata();
  },
  methods: {
    generateSections(data) {
      const total = data.total;

      const sections = [
        {
          label: this.$t('new'),
          value: Math.round((data.countNew / total) * 100),
          count: data.countNew,
          color: '#eeeeee',
        },
        {
          label: this.$t('statusInWork'),
          value: Math.round((data.countInJob / total) * 100),
          count: data.countInJob,
          color: '#2684FF',
        },
        {
          label: this.$t('registrationCompleted'),
          value: Math.round((data.countDecided / total) * 100),
          count: data.countDecided,
          color: '#57D9A3',
        },
      ];

      // Розрахунок залишкових значень для секції "Інше"
      const usedCount = data.countNew + data.countDecided + data.countInJob;
      const usedValue = sections.reduce((sum, section) => sum + section.value, 0);

      if (usedCount < total) {
        sections.push({
          label: this.$t('Other'),
          value: 100 - usedValue,
          count: total - usedCount,
          color: '#8777D9',
        });
      }

      return sections;

    },
    setHovered(section) {
        this.hoveredLabel = section.label;
        this.hoveredValue = section.value;
        this.activeSection = section;
    },
    resetHovered() {
        this.hoveredLabel = 'Hover over a section';
        this.hoveredValue = null;
        this.activeSection = null;
    },
    darkenColor(color, amount) {
        const colorValue = color.replace('#', '');
        const num = parseInt(colorValue, 16);
        let r = (num >> 16) - amount * 255;
        let g = ((num >> 8) & 0x00ff) - amount * 255;
        let b = (num & 0x0000ff) - amount * 255;
        r = r < 0 ? 0 : r > 255 ? 255 : r;
        g = g < 0 ? 0 : g > 255 ? 255 : g;
        b = b < 0 ? 0 : b > 255 ? 255 : b;
        return `rgb(${r}, ${g}, ${b})`;
    },
    changeWithClearFilter(name, value) {
      /** 
       * Зміна фільтру з очищенням попередніх
       *
       * @param {string} name - ім'я фільтру
       * @param {string} value - нове значення фільтру
      */

      
      this.objParams = {
        page: '1',
        pagelimit: '25',
        search: '',
        priority: '',
        status: '',
        decidedStatus: '',
        type: '',
        workerId: '',
        inJobWorkerId: '',
        inJobWorkerIds: [],
        problemEnvironment: '',
        pr1: '',
        pr2: '',
        tags: '',
        of: 'create_datetime',
        ot: 'desc'
      }

      this.objParams[name] = value

      this.getdata()
    },
    // скорочує назву тікету
    mutateTicketName(e) {
      let name = ''
      if(e.length > 50) {
        name = e.slice(0, 46) + '...'
      } else {
        name = e
      }
      return name
    },
    // функція-тур 
    createTour(){
      this.tour = this.$shepherd({
        useModalOverlay: true
      });
      const con = document.getElementById('info');
      this.tour.addStep({
        attachTo: { element: con, on: 'top' },
        text: 'Для перегляду інформаційних блокуів, натисніть сюди',
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: 'Back'
          },
          {
            action: this.tour.next,
            text: 'Next'
          }
        ],
      });
      const ddd = document.getElementById('ddd');
      
      this.tour.addStep({
        id: ddd,
        text: 'Income is up by 40%!! I guess the users who left were dead weight.',
        attachTo: {
            element: ddd,
            on: 'bottom'
        },
        buttons: [{
            text: 'Next, We Scroll!',
            // action: tour.next
        }]
    });

    },
    // очистити фільтр і оновити дані
    clearFilter(param) {
      this.objParams = {
        page: '1',
        pagelimit: '25',
        search: '',
        priorities: JSON.stringify([]),
        statuses: JSON.stringify([]),
        decidedStatuses: JSON.stringify([]),
        types: JSON.stringify([]),
        workerIds: JSON.stringify([]),
        inJobWorkerIds: JSON.stringify([]),
        problemEnvironments: JSON.stringify([]),
        pr1: '',
        pr2: '',
        tags: '',
        of: 'create_datetime',
        ot: 'desc'
      }
      if(!param) {
        this.getdata()
      }
    },
    // змінити ліміт і оновити дані
    changelimit: function(limit){
      this.objParams.pagelimit = limit;
      this.getdata();
    },
    getUnclosedTickets(){
      // отримання незакритих тікетів по номерам
      this.unclosedTicketsShow = true;
      // var objParamsTickets = {
      //   page: this.objParams.page,
      //   pagelimit: this.objParams.pagelimit,
      //   more: 3
      // }
      
      // apiServe.getUnclosedTickets(objParamsTickets).then(result => {
      //   if(result.status == 'done'){
      //     this.objPages = result.data;
      //     this.unclodesTickets.items = result.data.items;

      //     this.unclosedTicketsShow = true;
      //   } else {

      //   }
      // })
    },
    // отримання інформації
    getdata(page){
      this.showpag = true
      this.dataParams.status = true
      if(this.objParams['tags[]']) {
        delete this.objParams['tags[]']
        this.objParams.tags = ''
      }
      this.objParams.page = page != undefined ? page : '1';
      apiServe.getTickets(this.objParams).then(result => {
        if(result.status === 'done') {
          this.objPages = result.data;
          this.rows = result.data.items;  

          this.information[0].value = result.data.total
          this.information[1].value = result.data.countNew
          this.information[2].value = result.data.countInJob
          this.information[3].value = result.data.countDecided

          this.sections = this.generateSections({
            countDecided: result.data.countDecided,
            countInJob: result.data.countInJob,
            countNew: result.data.countNew,
            total: result.data.total,
          });

          // var checkColumn = this.rows.filter(item => item.problemEnvironment != null).length;
          // if(checkColumn === 0){
          //   this.columns[2].status = false
          // } else {
          //   this.columns[2].status = true
          // }

        } else {
          this.$toast.error(this.$t('error') + ` #300`);
        }
      }).catch(err => {
        this.$toast.error(this.$t('error') + ` # 300`);
      })
    },
    // приховати вирішені тікети
    // hideDecided(event){
    //   if(event.target.value == 'on'){
    //     this.rows = this.rows.filter(item => item.status != 'decided');
    //   } else {
    //     this.getdata();
    //   }
    // },
    // відкрити тікет
    open: function(e){
      var id = e.ticketId ? e.ticketId : e;
      this.ifPrevopen = id == this.rows[0].ticketId ? false : true;
      this.ifNextopen = id == this.rows[this.rows.length-1].ticketId ? false : true;
      
      apiServe.getTicket(id).then(result => {
        if(result.status == 'done'){

          if(!Array.isArray(result.data.userPhones)) {
            this.$toast.error(this.$t('error'));

            var errorText = 'Помилка відкриття тікету. Номери string'; 
            var statusRequest = "error";
            var api = 'getTicket';
            var fileRequest = 'src/views/newtickets/all.vue';
            var params = id;
            var response = result.data.userPhones

            // Викликаємо функцію для відправки повідомлення в телеграм
            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
            .then(response => console.log('Telegram API response:', response.data))
            .catch(error => console.error('Telegram API error:', error));

            this.obj = result.data;
            this.obj.userPhones = JSON.parse(result.data.userPhones)
            this.showModal = true;
            
          } else {
            this.obj = result.data;
            this.showModal = true;
          }
        } else {
          this.$toast.error(this.$t('error'));

          var errorText = 'Помилка відкриття тікету'; 
          var statusRequest = result.status;
          var api = 'getTicket';
          var fileRequest = 'src/views/newtickets/all.vue';
          var params = id;
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
    // закриття модалки з оновленням даних
    closeModal(){
      this.showModal = false
      // this.getdata(this.objParams.page)
    },
    // відкрити минули
    prevOpen(e){
      var current_id = e;
      var prev_id = ""; 

      for(var value in this.rows){
        if(this.rows[value].ticketId == current_id){
          prev_id = this.rows[Number(value)-Number('1')].ticketId;
        }
      }
      this.open(prev_id);
    },
    // відкрити наступне
    nextOpen(e){
      var current_id = e;
      var next_id = "";
      for(var value in this.rows){
        if(this.rows[value].ticketId == current_id){
          next_id = this.rows[Number(value)+Number('1')].ticketId;
        }
      }
      this.open(next_id);
    },
    // закрити модалку створення
    closeCreate(){
      this.permToEdit = false;
      this.createmodal = false;
      this.createmodalNewForm = false;
    },
    // запит на редагування тікету
    editTicket(e){
      this.permToEdit = true;
      this.form = e;
      this.createmodal = true;
    },
    // пошук
    searchB(e){
      this.objParams.search = e
      this.getdata();
    },
    sortColumn(e, o){
      // сортування
      this.objParams.of = e.sort
      this.objParams.ot = o == 'desc' ? 'asc' : 'desc'
      this.getdata()
    },
    // В окрему функцію виносимо роботу з датою, бо функція велика
    dateWork(value, el, action){
      // Функція в "найкращих традиціях" не має якоїсь сталої структури
      if(value[el][0]?.value === 'today') {
        // Якщо за сьогодні
        this.objParams.pr1 = new Date().toLocaleString().split(',')[0].split('.').reverse().join('-')
        this.objParams.pr2 = new Date().toLocaleString().split(',')[0].split('.').reverse().join('-')
      } else if(value[el][0].value === 'yesterday') {
        // Якщо за вчора, то треба деякі додаткові маніпуляції
        let dateArr = new Date().toLocaleString().split(',')[0].split('.').reverse()
        dateArr[2] = (Number(dateArr[2]) - 1)
        this.objParams.pr1 = dateArr.join('-')
        this.objParams.pr2 = new Date().toLocaleString().split(',')[0].split('.').reverse().join('-')
      }

      if(action) {
        this.getdata()
      }
    },
    // змінити таб
    changeTab(name, value){
      // Все дуже просто, але єбанись яка логіка
      console.log('name', name, 'value', value);
      this.clearFilter('forTab') //очистити фільтр

      // Якщо таб має одразу декілька опцій, то падаємо в першу гілку 
      // Починаємо боротися на ножаї
      if(Array.isArray(value) === true) {
        value = Object.groupBy(value, ({obj}) => obj) 
        /* 
          Object.groupBy - топова функція в об'єктах, що повертає об'єкт,
          де ключами буде вказаний параметр з масиву об'єктів
        */
        Object.keys(value).forEach(el => {
          // Дата дуже слизький параметр, з ним працюємо обережно і одразу виносимо в іншу гілку
          if(el === 'pr1') {
            this.dateWork(value, el)
          } else if(el === 'search') {
            // Ще одна "редиска", нема підтримки мультиопцій, беремо перше

            this.objParams.search = value[el][0].value
          } else {
            // Дефолтне сприйняття, коли нема якихось не оптимізованих
            // Для мультизапиту значень
            // Просто пушимо потрібне в масив

            this.objParams[el] = []
            value[el].forEach(item => {
              this.objParams[el].push(item.value)
            })
            
            // Stringify береже нас прекрасний бекенд <3
            this.objParams[el] = JSON.stringify(this.objParams[el])
          }
        })
      } else {
        // Значення в табі одне. Умовно, просто фільтр за "сьогодні" без доп опцій. 
        // Пушимо в масив і дублюємо роботу з датою та пошуком
        if(name == 'pr1') {
          // Робимо mock, щоб працювала функція dateWork
          // P.S. найобувати погано, але "вибач мене JS"
          const obj = {}
          obj[name] = [{"value": value}]
          this.dateWork(obj, name, 'getData') // сюда летить getData, як команда
          return
        } else if(name == 'search'){
          this.objParams[name] = (value)
        } else {
          this.objParams[name] = JSON.parse(this.objParams[name])
          this.objParams[name].push(value)
          this.objParams[name] = JSON.stringify(this.objParams[name])
        }
      }
      
      this.getdata();
    },
    // мутейт місяця
    mounthMutate(e) {
      var c = ''
      switch (e) {
        case 'Feb':
          c = '2'
        break;
        case 'Mar':
          c = '3'
        break;
        case 'Apr':
          c = '4'
        break;
        case 'May':
          c = '5'
        break;
        case 'Jun':
          c = '6'
        break;
        case 'Jul':
          c = '7'
        break;
        case 'Aug':
          c = '8'
        break;
        case 'Sep':
          c = '9'
        break;
        case 'Oct':
          c = '10'
        break;
        case 'Nov':
          c = '11'
        break;
        case 'Dec':
          c = '12'
        break;
        case 'Jan':
          c = '1'
        break;
      }
      return c
    },
    // змінити фільтр
    changeFilter(name, value){
      console.log(name, value)
      if(name === 'pr') {
        this.objParams.pr1 = "2022-02-24"
        this.objParams.pr2 = value
        this.getdata()
      }else if(name === 'priority' || name === 'workerId') {
        this.objParams[name] = value
        switch (name) {
          case 'priority':
            this.objParams.priorities.push(value)
            break;
          case 'workerId':
            this.objParams.workerIds.push(value)
            break;
        }
        this.getdata()
      } else if(name === 'all'){
        this.clearFilter()
      } else if(Array.isArray(value)){
        // Застосовуємо мультифільтри
        this.objParams[name] = []
        if(value.length > 0 && value[0] != '') {
          value.forEach(el => {
            this.objParams[name].push(el)
          })
        }
        this.objParams[name] = JSON.stringify(this.objParams[name]) 
        this.getdata()
      }
    },
    // змінити таб (його значення)
    changeTabs(items){
      localStorage.setItem("Ticketscheckbox", JSON.stringify(this.othertabs));
      localStorage.setItem("resultTickets", this.othertabs.length)
      // this.getdata()
    },
    // видалити таб
    removeCategory(e, i){
      this.othertabs.splice(i, 1)
      localStorage.setItem("Ticketscheckbox", JSON.stringify(this.othertabs));
    },
    // мутейт співробітника
    nameWorker(item){
      return nameWorker(item)
    },
    // мутейт телефонів клієнта
    mutateArray(value, field){
      var r = []
      if(value != ''){
        if(field === 'userPhones') {
          for(var item in value){
            r.push('<span class="fs-11 badge badge-soft-info">'+value[item].number+'</span>')
          }
        } else if(field === 'assignedWorkers') {
          for(var item in value){
            r.push('<span class="fs-11 badge badge-soft-danger">'+value[item].workerName+'</span>')
          }
        } else if(field === 'inJobWorkers') {
          for(var item in value){
            r.push('<span class="fs-11 badge badge-soft-success">'+value[item].workerName+'</span>')
          }
        }
      }
      return r
    },  
    mutateticket(e){
      // мутейт типу тікета
      return mutateTypeTicket(e)
    },
    mutatestatus(e){
      // мутейт статусу
      return mutateStatusTicket(e)
    },
    mutateDecidedStatus(e){
      // мутейт типу статусу, коли тікет "Виконано"
      return mutateDecidedStatusTicket(e)
    },
    mutatepriority(e){
      // мутейт пріорітету
      return mutatePriorityTicket(e)
    },
    mutateProblemEnv(e){
      
      // return this.developmentProjects.find(el => el.projectKey === e).projectName
      const project = this.developmentProjects.find(el => el.projectKey === e);

      if (!project) {
        console.warn(`Проєкт з ключем ${e} не знайдено`);
        
        return "Проєкт з ключем " + e + " не знайдено"; // або поверніть значення за замовчуванням
      }

      return project.projectName;
    },
    nameTag(value){
      var r = []
      if(value != ''){
        for(var item in value){
          r.push('<span class="fs-11 fw-semibold" style="background:' + nameTag(value[item]).color + ';border-radius:5px;padding:5px;color:white;">'+nameTag(value[item]).name+'</span>')
        }
      }
      return r
    },
    toggleButton(button) {
      // Якщо кнопку натискають вдруге — вимикаємо її
      if (this[button]) {
        this[button] = false;
      } else {
        // Скидаємо всі інші кнопки
        this.unclosedTicketsShow = false;
        this.unclosedTicketsByWorkersShow = false;
        this.unclosedTicketsByEnvironmentsShow = false;

        // Вмикаємо лише ту кнопку, яку натиснули
        this[button] = true;
      }
    }
  },
  computed: {
    perms(){
      return storeS.perms
    },
    workers() {
      return storeS.workers
    },
    checks() {
      return storeS.checks
    },
    user(){
      return storeS.userbase
    },
    statusTheme(){
      // Отримання статусу теми
      return storeS.nightTheme
    },
    highlightedSections() {
      return this.sections.map((section) => ({
          ...section,
          color:
          section === this.activeSection
              ? this.darkenColor(section.color, 0.2)
              : section.color,
      }));
    },
    developmentProjects() {
        // Отримання проектів
        return storeS.developmentProjects
    }
  },
  mounted() {
    // гайд
    // this.createTour();
    // this.tour.start();
    // гайд

    // якщо існують тікети в сторі, то дістаємо їх
    this.othertabs = localStorage.getItem("Ticketscheckbox") ? JSON.parse(localStorage.getItem("Ticketscheckbox")) : [];

    // якщо юзер девелопер і не має айді 34, то видаємо йому таб "Мої" на взяття в роботу
    if(this.user.workerJob == 'developer' && this.user.userid != 34) {
      if(this.othertabs[0] == undefined || this.othertabs[0]?.value != this.user.userid){
        this.othertabs.unshift({
          checked: true,
          color: "#1ABC9C",
          obj: "inJobWorkerIds",
          tab: "inJobWorkerIds"+this.user.userid,
          title: this.$t("My"),
          value: this.user.userid
        })
      }
    } else {
      // видаємо таб "Мої", котрі створені 
      if(this.othertabs[0] == undefined || this.othertabs[0]?.value != this.user.userid){
        this.othertabs.unshift({
          checked: true,
          color: "#1ABC9C",
          obj: "workerIds",
          tab: "workerIds"+this.user.userid,
          title: this.$t("My"),
          value: this.user.userid
        })
      }
    }

    this.eventBus.off('saveTickets');
    this.eventBus.on('saveTickets', (status) => {
      if(status == true) {
        this.getdata();
        // сокет на збереження тікету
      }
    }),
    
    this.eventBus.off('closeTicket');
    this.eventBus.on('closeTicket', (status) => {
        if(status == true){
            this.eventBus.emit('saveTickets', true)
            this.$emit('close')
        // сокет на закриття тікету
        }
    }),
    this.eventBus.off('openTickets');
    this.eventBus.on('openTickets', (id) => {
        if(id != ''){
          this.createmodal = false
          this.open(id)
          // Відкриття тікету
        }
    })
  }
};
</script>

<style scoped>
.tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
}
.cdc-container_flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.legend {
  list-style-type: none;
  padding: 0;
}
.legend li {
  margin: 5px 0;
  font-size: 14px;
}
</style>
